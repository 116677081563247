.about-company {
  height: 100%;
  background: #0144D3;
  padding: 8% 0;
  box-sizing: content-box;
  .container {
    width: 1200px;
    margin: 0 auto;
    .about-company-wrapper {
      display: flex;
      flex-direction: column;
      align-content: center;
      .title {
        width: 100%;
        text-align: left;
        padding-bottom: 5%; }
      .content-about-company {
        display: flex;
        flex-direction: row;
        box-sizing: content-box;
        .subscribe {
          width: 58%;
          .subtitle {
            padding-bottom: 7%; }
          .mapImage {
            width: 100%;
            max-width: 691px; } }
        .facts {
          width: 42%;
          padding-left: 4%;
          display: grid;
          grid-template-columns: repeat(2,auto);
          grid-template-rows: repeat(3,auto);
          &__item {
            flex-direction: column;
            width: 100%;
            padding-bottom: 10%;
            display: flex;
            align-items: center;
            &_num {
              font-family: 'Montserrat', sans-serif;
              font-style: normal;
              font-weight: 300;
              font-size: 64px;
              line-height: 78px;
              text-align: center;
              color: #FFFFFF;
              margin: 0 0 10px 0;
              transition: 0.5s; }
            &_text {
              font-family: 'Montserrat', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 26px;
              color: #FFFFFF;
              text-align: center;
              margin: 0 0 24px 0;
              width: 200px; } } } } } } }



@media only screen and (max-width : 1250px) {
  .about-company .container .about-company-wrapper .content-about-company .facts {
    padding-left: 1%; } }



@media only screen and (max-width : 1024px) {
  .about-company {
    .container {
      width: 800px;
      .about-company-wrapper {
        .content-about-company .facts__item_num {
          font-size: 54px;
          margin: 0; }
        .content-about-company .facts__item_text {
          font-size: 16px;
          margin: 0; } } } } }



@media only screen and (max-width : 950px) {
  .about-company {
    .container {
      .about-company-wrapper {
        .content-about-company {
          flex-wrap: wrap;
          align-items: center;
          display: flex;
          .subscribe {
            width: 100%;
            align-items: center;
            display: flex;
            flex-direction: column;
            .subtitle {
              width: 500px;
              display: flex;
              align-self: start; } }
          .facts {
            width: 100%;
            grid-template-columns: repeat(2,240px);
            justify-content: center;
            margin: 0 auto;
            &__item {
              padding-bottom: 5%; } } } } } } }


@media only screen and (max-width : 768px) {
  .content-about-company {
    flex-wrap: wrap; }

  .about-company .container .about-company-wrapper .content-about-company {
    .facts__item_num {
      font-size: 40px; }
    .subscribe .map {
      width: 60%; }
    .subscribe .subtitle {
      width: 100%; } } }

@media only screen and (max-width : 600px) {
  .about-company {
    padding: 12% 0;
    .container .about-company-wrapper .content-about-company {
      .facts__item_text {
        font-size: 13px;
        width: 150px; } } } }

@media only screen and (max-width : 600px) {
  .about-company .container .about-company-wrapper .content-about-company .facts {
    grid-template-columns: repeat(2, 155px); } }

@media only screen and (max-width : 450px) {
  .about-company .container .about-company-wrapper .title {
    padding-bottom: 30px; } }

@media only screen and (max-width : 300px) {
  .about-company .container .about-company-wrapper .content-about-company .facts {
    grid-template-columns: repeat(2, 120px); } }

@media only screen and (max-width: 350px) {
  .about-company .container .about-company-wrapper .content-about-company .facts {
    grid-template-columns: repeat(2, 145px); } }
