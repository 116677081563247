.load-wrapper {
  width: 100%;
  height: 100vh;
  background-color: #3590D6ab;
  z-index: 999;
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;

  .load-popup {
    width: 300px;
    height: 150px;
    background-color: #FFFFFF;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    &__text {
      color: #3590D6;
      font-size: 20px;
      text-align: center;
      font-weight: 600; } } }
