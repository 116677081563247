.provider::after {
  background-image: url('../../media/img/sklad.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 804px auto;
  display: inline-block;
  width: 804px;
  height: 100%;
  content: "";
  position: absolute;
  right: 0;
  top: 0; }
.provider {
  position: relative;
  background-color: #0144D3;
  height: 100vh;
  display: flex;
  align-items: center;
  box-sizing: content-box;
  .container {
    margin: 0 auto;
    width: 1200px;
    position: relative;
    .wrapper {
      .line {
        position: absolute;
        width: 274.03px;
        height: 0;
        left: -95px;
        top: 327px;
        border: 1px solid #FFFFFF;
        transform: rotate(90deg);
        z-index: 0; }
      .title {
        padding-bottom: 50px; }
      .requirements {
        position: relative;
        z-index: 10;
        &__item {
          display: flex;
          flex-direction: row;
          margin-bottom: 55px;
          cursor: default;
          &_circle:hover {
            transform: scale(1.15);
            transition: 0.3s;
            cursor: default; }
          &_circle {
            width: 80px;
            height: 80px;
            background: #0144D3;
            border: 2px solid #FFFFFF;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Montserrat',sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 40px;
            line-height: 48px;
            color: #FFFFFF;
            cursor: default; }
          &_text {
            width: 305px;
            padding-left: 2%;
            display: flex;
            align-items: center;
            cursor: default; } } } } } }

@media only screen and (max-width : 2560px) {
  .provider {
    height: 90vh; }
  .provider::after {
    background-size: 1100px auto;
    width: 1100px;
    height: 100vh; } }

@media only screen and (max-width : 1920px) {
  .provider::after {
    background-size: 804px auto;
    width: 804px; }
  .provider {
    height: 100vh; } }


@media only screen and (max-width : 1250px) {
  .provider::after {
    width: 580px; } }

@media only screen and (max-width : 1024px) {
  .provider::after {
    width: 490px;
    height: 100vh; }
  .provider .container {
    width: 800px;
    .wrapper {
      .line {
        width: 250px;
        left: -89px; }
      .requirements__item_circle {
        width: 70px;
        height: 70px;
        font-size: 30px; } } } }

@media only screen and (max-width : 950px) {
  .provider::after {
    width: 390px; } }

@media only screen and (max-width : 850px) {
  .provider::after {
    width: 0;
    height: 0;
    display: none; }
  .provider {
    background-image: url('../../media/img/sklad.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .container {
      background: #0144D3;
      .wrapper {
        width: 70%;
        margin: 20px auto 0 auto;
        .line {
          left: 17px; } } } } }
@media only screen and (max-width : 800px) {
  .provider .container {
    .wrapper {
      .line {
        width: 230px;
        left: 23px;
        top: 280px; }
      .requirements__item_circle {
        width: 60px;
        height: 60px;
        font-size: 26px; } } } }

@media only screen and (max-width : 770px) {
  .provider .container {
    .wrapper {
      .line {
        left: 8px; } } } }

@media only screen and (max-width : 768px) {
  .provider {
    .container {
      background: #0144D3;
      backdrop-filter: blur(10px);
      .wrapper {
        width: 70%;
        margin: 20px auto 0 auto; } } } }

@media only screen and (max-width : 650px) {
  .provider .container {
    .wrapper {
      .line {
        left: -13px; }
      .requirements__item_circle {
        width: 50px;
        height: 50px;
        font-size: 23px; }
      .requirements__item_text {
        width: 250px; } } } }

@media only screen and (max-width : 550px) {
  .provider .container {
    .wrapper {
      .line {
        left: -20px; }
      .requirements__item_circle {
        width: 50px;
        height: 50px;
        font-size: 23px; }
      .requirements__item_text {
        width: 200px; } } } }

@media only screen and (max-width : 500px) {
  .provider .container {
    .wrapper {
      .line {
        left: -29px; }
      .requirements__item_circle {
        width: 48px;
        height: 48px; } } } }


@media only screen and (max-width : 450px) {
  .provider .container {
    .wrapper {
      width: 90%;
      .line {
        width: 206px;
        left: -58px; }
      .requirements__item_circle {
        width: 48px;
        height: 48px; } } } }
@media only screen and (max-width : 390px) {
  .provider .container {
    .wrapper {
      .line {
        left: -59px; }
      .requirements__item_text {
        width: 190px; } } } }

@media only screen and (max-width : 380px) {
  .provider .container {
    width: 330px !important;
    .wrapper {
      .line {
        left: -62px; } } } }


@media only screen and (max-width : 350px) {
  .provider .container {
    width: 312px !important; } }

@media only screen and (max-width : 320px) {
  .provider .container {
    width: 300px !important;
    .wrapper {
      .title {
        h2 {
          font-size: 20px; } } } } }


