.new-popup .closePopup {
  animation: fadeOut .6s forwards; }
.new-popup {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  animation: fade .6s forwards;
  overflow: auto;
  opacity: 0;
  .new {
    background-color: rgba(255, 255, 255, 1);
    height: auto;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5% 0;
    overflow: auto;
    .new-container {
      height: 100%;
      width: 85%;
      margin: 0 auto;
      .title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        p.date {
          font-weight: 700;
          font-size: 16px;
          letter-spacing: 3px;
          text-transform: uppercase;
          color: #3590D6; }
        button {
          background-color: transparent;
          border: none; } }
      p.title {
        font-weight: 600;
        font-size: 24px;
        color: #3590D6;
        padding: 4% 0 4% 0; }
      p.body {
        color: #3590D6;
        font-weight: 400;
        font-size: 18px; } } } }


@keyframes fade {
  100% {
    opacity: 1;
    transition: 0.3s; } }

@keyframes fadeOut {
  100% {
    opacity: 0;
    transition: 0.3s; } }


@media only screen and (max-width : 1250px) {
  .new-popup .new {
    width: 90%;
    height: 90%; } }

@media only screen and (max-width : 600px) {
  .new-popup .new .new-container p.body {
      font-size: 12px; } }

@media only screen and (max-width : 320px) {
  .new-popup .new .new-container {
    overflow: scroll; } }
