@import 'media/fonts/stylesheet.css';


@mixin fonts($weight, $size, $color) {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  color: $color;
  margin: 0; }
