.map {
  position: relative;
  .connection {
    width: 35%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 100;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    .connection-wrapper {
      padding-left: 10%;
      box-sizing: border-box;
      .title {
        padding: 7% 0 5% 0;
        width: 90%;
        h3 {
          color: rgba(1, 68, 211, 0.5)!important; }
        h2 {
          color: #0144D3; } }
      .contactsData {
        width: 90%;
        .contactsData_item {
          display: flex;
          flex-direction: row;
          align-items: start;
          margin-bottom: 10%;
          .contactsData_item__img {
            width: 32px;
            min-width: 32px;
            padding-right: 20px;
            display: flex;
            justify-content: center; }
          .contactsData_item__text {
            a {
              text-decoration: none;
              p {
                color: #0144D3; } }
            p {
              color: #0144D3; }
            .bukh {
              padding-top: 10%; } } } } } }

  .ymap div {
    height: 100vh!important;
    width: 100%!important; } }


@media only screen and (max-width: 1024px) {
  .map {
    .connection {
      width: 40%;
      .connection-wrapper {
        .title {
          padding: 7% 0 5% 0; } } }
    .ymap div {
      height: 80vh!important; } } }

@media only screen and (max-width: 650px) {
  .map {
    .connection {
      height: auto;
      width: 100%;
      position: absolute;
      box-sizing: content-box;
      top: 231px;
      left: 0;
      .connection-wrapper {
        padding-left: 0;
        width: 500px;
        .contactsData .contactsData_item {
          margin-bottom: 10px;
          .contactsData_item__img {
            padding-right: 10px; } } } }
    .title {
      padding: 5% 0 3% 0; } } }

@media only screen and (max-width: 550px) {
  .map .connection .connection-wrapper {
    width: 450px; } }

@media only screen and (max-width: 500px) {
  .map .connection .connection-wrapper {
    width: 400px; } }

@media only screen and (max-width: 450px) {
  .map {
    .connection {
      top: 347px;
      .connection-wrapper {
        width: 350px;
        .title {
          padding: 7% 0 3% 0; }
        .contactsData {
          width: 90%;
          .contactsData_item {
            .contactsData_item__text .bukh {
              padding-top: 5%; }
            .contactsData_item__img {
              width: 28px;
              img {
                max-width: 28px; }
              .phone {
                max-width: 21px; } } } } } }
    .ymap div {
      height: 100vh!important; } } }

@media only screen and (max-width: 380px) {
  .map .connection {
    top: 230px;
    .connection-wrapper {
      width: 300px; } } }




