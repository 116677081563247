.sidebar {
  height: 100vh;
  width: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
  position: absolute;
  right: -14%;
  top: 0;
  backdrop-filter: blur(20px);
  z-index: 4;
  transform: translateX(100%);
  animation: sidebar 1s forwards ease-out;
  &__container {
    width: 90%;
    .nav__item.close {
      display: flex;
      justify-content: flex-end;
      padding: 20px 25px 10px 25px; }
    .nav__item {
      padding-top: 25px;
      a {
        text-decoration: none;
        color: #FFFFFF;
        font-size: 16px;
        padding-left: 25px; }
      .collaborationRef:hover {
        border-left: 2px solid #0144D3;
        padding-left: 25px; }
      .catalogRef:hover {
        border-left: 2px solid #0144D3;
        padding-left: 25px; }
      .newsRef:hover {
        border-left: 2px solid #0144D3;
        padding-left: 25px; }
      .contactsRef:hover {
        border-left: 2px solid #0144D3;
        padding-left: 25px; } } } }


@keyframes sidebar {
  100% {
    transform: translateX(0); } }
@media only screen and (max-width : 425px) {
  .sidebar {
    width: 70%; } }

@media only screen and (max-width : 375px) {
  .sidebar {
    &__container {
      .nav__item.close {
        padding: 20px 10px 10px 10px; } } } }
