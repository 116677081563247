#catalog {
  height: 100%;
  padding-top: 8%;
  background-color: #3590D6;
  .container {
    margin: 0 auto;
    width: 1200px;
    .title {
      padding-bottom: 5%; }
    .catalog__items {
      display: grid;
      grid-template-columns: repeat(3, auto);
      gap: 10px;
      &_item {
        padding-bottom: 5%;
        display: flex;
        flex-direction: row;
        gap: 10px;
        &__image {
          width: 40px;
          height: 40px;
          justify-content: center;
          display: flex; }
        &__subscribe {
          h4 {
            padding-bottom: 10px; }
          p {
            padding-bottom: 7px; } } } }
    .catalog__button {
      display: flex;
      flex-direction: column;
      align-items: center;
      button:hover {
        transition: 0.3s;
        background-color: #3590D6;
        border: 3px solid #FFFFFF;
        color: #FFFFFF; }
      button {
        padding: 24px 64px;
        width: 384px;
        height: 70px;
        background-color: #0144D3;
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        color: #FFFFFF;
        border: none;
        margin-top: 40px; } } } }


@media only screen and (max-width : 768px) {
  #catalog {
    .container {
      .catalog__items {
        display: grid;
        grid-template-columns: repeat(2, auto); }
      .catalog__items_item__image {
        width: 30px;
        height: 30px; } } } }

@media only screen and (max-width : 500px) {
  #catalog {
    padding-top: 12%; } }

@media only screen and (max-width : 500px) {
  #catalog {
    .container {
      .catalog__items {
        display: grid;
        grid-template-columns: repeat(1, auto); }
      .catalog__button {
        button {
          padding: 20px 32px 20px;
          width: 100%;
          height: 57px;
          font-size: 14px; } } } } }

@media only screen and (max-width : 450px) {
  #catalog .container {
    .title {
      padding-bottom: 30px; }
    .catalog__items_item__image {
      width: 25px;
      height: 25px; } } }

