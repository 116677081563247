.partners {
  background-color: #3590D6;
  height: 100%;
  padding-top: 8%;
  .container {
    padding-top: 2%;
    width: 1200px;
    margin: 0 auto;
    .title {
      width: 100%;
      padding-bottom: 5%; } }

  .logos {
    overflow: hidden;

    &__line1 {
      animation: logos 40s linear infinite;
      display: flex;
      flex-direction: row;
      width: 800%;
      transition: 0.3s;
      margin-bottom: 40px;
      .logo {
        margin-right: 20px;
        img {
          width: 100%;
          margin-right: 20px; } } }
    &__line2 {
      animation: logos2 40s linear infinite;
      display: flex;
      flex-direction: row;
      width: 800%;
      transition: 0.3s;
      .logo {
        margin-right: 20px;
        img {
          width: 100%;
          margin-right: 20px; } } } } }



@keyframes logos {
  0% {
    transition: 0.3s;
    transform: translateX(0); }
  100% {
    transition: 0.3s;
    transform: translateX(-50%); } }

@keyframes logos2 {
  0% {
    transition: 0.3s;
    transform: translateX(-50%); }
  100% {
    transition: 0.3s;
    transform: translateX(0); } }


@media only screen and (max-width : 768px) {
  .partners .logos__line1 .logo {
    margin-right: 30px;
    img {
      width: 100%; } } }

@media only screen and (max-width : 600px) {
  .partners .logos__line1 {
    margin-bottom: 20px;
    .logo {
      margin-right: 30px;
      margin-top: 10px; }
    img {
      width: 100%; } } }

@media only screen and (max-width : 450px) {
  .partners {
    .logos__line1, .logos__line2 {
      width: 1000%; }
    .container .title {}
    padding-bottom: 30px; } }
