#contacts {
  background: #0144D3;
  height: 100%;
  padding: 4% 0;
  box-sizing: border-box;
  .container {
    width: 1200px;
    height: auto;
    margin: 0 auto;
    .contacts {
      display: grid;
      grid-template-columns: repeat(2,auto);
      margin-bottom: 5%;
      &__item {
        margin-right: 15%;
        h4 {
          margin-bottom: 10%; }
        &_phone {
          display: grid;
          grid-template-columns: 200px 100px;
          margin-bottom: 8%;
          .number a {
            text-decoration: none;
            color: white; } } } } } }


@media only screen and (max-width : 950px) {
  #contacts {
    padding-top: 8%; } }


@media only screen and (max-width : 820px) {
  #contacts .container .contacts .contacts__item {
    .contacts__item_phone {
      grid-template-columns: 160px 90px;
      gap: 5px; } } }


@media only screen and (max-width : 650px) {
  #contacts {
    padding-top: 5%;
    .container {
      .contacts {
        display: grid;
        grid-template-columns: repeat(1,auto);
        .contacts__item {
          h4 {
            margin-bottom: 5px;
            margin-top: 20px; }
          .contacts__item_phone {
            grid-template-columns: 140px 80px;
            margin-top: 10px;
            margin-bottom: 0; } } } } } }


@media only screen and (max-width : 450px) {
  #contacts {
    .container {
      .contacts {
        .contacts__item {
          .contacts__item_phone {
            margin-top: 4px;
            p {
              font-size: 12px!important; } } } }
      .contacts__item h4 {
        font-size: 12px!important; } } } }
