@import "Global";

$value-h4: 600, 24px, #ffffff;
h4 {
  @include fonts($value-h4...); }

$value-h3: 700, 16px, rgba(255, 255, 255, 0.5);
h3 {
  @include fonts($value-h3...);
  letter-spacing: 3px;
  text-transform: uppercase; }

$value-h2: 700, 36px, #FFFFFF;
h2 {
  @include fonts($value-h2...);
  letter-spacing: 1px;
  text-transform: uppercase; }

$value-p: 400, 18px, #FFFFFF;
p {
  @include fonts($value-p...); }




@media only screen and (max-width : 1250px) {
  .container {
    width: 1000px!important; } }

@media only screen and (max-width : 1024px) {
  p {
    font-size: 16px;
    line-height: 20px; }
  h2 {
    font-size: 27px; }
  h3 {
    font-size: 12px; }
  h4 {
    font-size: 18px; }
  .container {
    width: 800px!important; } }

@media only screen and (max-width : 950px) {
  .container {
    width: 700px!important; } }

@media only screen and (max-width : 768px) {
  p {
    font-size: 14px; }
  h2 {
    font-size: 24px; }
  h3 {
    font-size: 10px; }
  .container {
    width: 600px!important; } }

@media only screen and (max-width : 650px) {
  .container {
    width: 500px!important; }
  p {
    font-size: 14px; } }

@media only screen and (max-width : 550px) {
  .container {
    width: 450px!important; } }

@media only screen and (max-width : 500px) {
  .container {
    width: 400px!important; }
  p {
    font-size: 14px; } }

@media only screen and (max-width : 450px) {
  .container {
    width: 350px!important; }
  p {
    font-size: 14px; } }


@media only screen and (max-width : 380px) {
  .container {
    width: 300px!important; } }

@media only screen and (max-width : 310px) {
  .container {
    width: 280px!important; } }

