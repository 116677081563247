.form-popup {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  animation: fade .6s forwards;
  overflow: auto;
  opacity: 0;
  .form {
    padding: 30px 0;
    height: 600px;
    background-color: rgba(255, 255, 255, 1);
    width: 1008px;
    display: flex;
    align-items: center;
    justify-content: center;
    .form-container {
      width: 80%;
      margin: 0 auto;
      .form-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .title {
          margin: 50px 0 60px 0;
          h3 {
            color: rgba(53, 144, 214, 0.5); }
          h2 {
            color: #3590D6; } }
        button {
          border: none;
          background-color: transparent; } }
      form {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        .form-submit:hover {
          background-color: #0144D3;
          transition: 0.3s; }
        .form-submit {
          display: flex;
          justify-content: center;
          padding: 24px 64px 23px;
          width: 390px;
          height: 69px;
          background-color: #3590D6;
          border: none;
          font-weight: 600;
          font-size: 18px;
          color: #FFFFFF; }
        input {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none; }
        a {
          font-family: 'Montserrat',sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 18px;
          color: #3590D6;
          padding: 16px 0 50px 0;
          width: 390px; }
        .form-items {
          display: grid;
          justify-content: space-between;
          grid-template-columns: repeat(2, 400px);
          grid-template-rows: repeat(2, auto);
          padding-bottom: 30px;
          .item1 {
            grid-column: 1 / 2;
            grid-row: 1 / 2; }
          .item2 {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
            margin-top: 35px; }
          .item3 {
            grid-column: 2 / 3;
            grid-row: 1 / 2; }
          .item4 {
            grid-column: 2 / 3;
            grid-row: 2 / 3;
            margin-top: 35px; }
          &__item {
            label {
              font-family: 'Montserrat', sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 22px;
              color: #3590D6;
              display: block; }
            .form-input {
              width: 360px;
              height: 34px;
              background: #FFFFFF;
              border: none;
              border-bottom: 2px solid #3590D6;
              display: block; } } } } } } }




@keyframes fade {
  100% {
    opacity: 1; } }



@media only screen and (max-width : 1024px) {
  .form-popup .form {
      width: 800px;
      height: 500px;
      .form-container {
        width: 700px;
        .form-title {
          padding: 5% 0 0 0; }
        form {
          .form-items {
            grid-template-columns: repeat(2, 350px);
            padding-bottom: 15px; }
          .form-items__item .form-input {
            width: 300px; }
          .form-submit, .agree {
            width: 330px; }
          .agree {
            font-size: 14px; } } } } }

@media only screen and (max-width : 850px) {
  .form-popup .form {
      width: 700px;
      .form-container {
        width: 600px;
        .form-title {
          padding: 1% 0 0 0; }
        form {
          width: 600px;
          .form-items {
            grid-template-columns: repeat(2, 300px); }
          .form-items__item .form-input {
             width: 280px; }
          .form-submit {
            font-size: 16px;
            width: 300px;
            padding: 20px 64px 20px;
            height: 60px; }
          .agree {
             width: 300px; } } } } }

@media only screen and (max-width : 768px) {
  .form-popup .form {
      width: 650px;
      height: 450px;
      .form-container {
        width: 550px;
        form {
          width: 550px;
          .form-items {
            grid-template-columns: repeat(2, 275px); }
          .form-items__item .form-input {
            width: 245px; } } } } }


@media only screen and (max-width : 700px) {
  .form-popup .form {
      width: 600px;
      .form-container {
        width: 550px;
        form {
          width: 550px;
          .form-items {
            grid-template-columns: repeat(2, 275px); }
          .form-items__item .form-input {
            width: 245px; }
          .agree {
            width: 301px; } }
        .form-title button {
          padding: 0 25px 0 0; } } } }


@media only screen and (max-width : 650px) {
  .form-popup .form {
      width: 600px;
      height: 600px;
      .form-container {
        width: 500px;
        .form-title button {
          padding: 0; }
        form {
          width: 500px;
          .form-items {
            grid-template-columns: repeat(1, 500px);
            grid-template-rows: repeat(4, auto);
            .item1 {
              grid-column: 1 / 2;
              grid-row: 1 / 2;
              margin-top: -20px; }
            .item2 {
              grid-column: 1 / 2;
              grid-row: 2 / 3;
              margin-top: 20px; }
            .item3 {
              grid-column: 1 / 2;
              grid-row: 3 / 4;
              margin-top: 20px; }
            .item4 {
              grid-column: 1 / 2;
              grid-row: 5 / 6;
              margin-top: 20px; } }

          .form-items__item .form-input {
             width: 97%; }
          .form-submit {
            font-size: 16px;
            width: 100%;
            padding: 20px 64px 20px;
            height: 60px; }
          .agree {
            width: 100%; } } } } }



@media only screen and (max-width : 600px) {
  .form-popup .form {
    width: 530px;
    .form-container {
      width: 90%;
      form {
        width: 100%;
        .form-items {
          grid-template-columns: repeat(1, 477px); } } } } }


@media only screen and (max-width : 550px) {
  .form-popup .form {
    width: 480px;
    .form-container {
      form {
        .form-items {
          grid-template-columns: repeat(1, 432px); } } } } }

@media only screen and (max-width : 500px) {
  .form-popup .form {
    width: 450px;
    .form-container {
      form {
        .form-items {
          grid-template-columns: repeat(1, 405px); } } } } }

@media only screen and (max-width : 460px) {
  .form-popup .form {
    width: 380px;
    .form-container {
      form {
        .form-items {
          grid-template-columns: repeat(1, 332px); } } } } }

@media only screen and (max-width : 400px) {
  .form-popup .form {
    width: 320px;
    .form-container {
      form {
        .form-items {
          grid-template-columns: repeat(1, 281px); } } } } }

@media only screen and (max-width : 340px) {
  .form-popup .form {
    width: 300px;
    .form-container {
      form {
        .form-items {
          grid-template-columns: repeat(1, 264px); } } } } }

@media only screen and (max-width : 310px) {
  .form-popup .form {
    width: 280px;
    .form-container {
      form {
        .form-items {
          grid-template-columns: repeat(1, 246px); } } } } }




