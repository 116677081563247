header {
  background-image: url("../../media/img/background.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  .container {
    width: 1200px;
    height: auto;
    margin: 0 auto;
    position: relative;
    z-index: 3;
    opacity: .99; }

  .header-components {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 100px);
    &__description {
      h1 {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        color: #FFFFFF; }
      p {
        margin-bottom: 8%;
        width: 360px; } }
    a {
      text-decoration: none;
      display: block;
      padding: 24px 64px;
      width: 384px;
      height: 70px;
      background: #3590D6;
      border: none;
      box-sizing: border-box;

      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #FFFFFF; }
    a:hover {
      background: #0144D3;
      transition: 0.3s; } } }

@media only screen and (max-width : 1024px) {
  header {
    .container {
      width: 800px;
      .nav {
        &__logo img {
          width: 50px; } } }

    .header-components {
      width: 80%;
      &__description {
        h1 {
          font-size: 30px; } }
      a {
        width: 320px; } } } }


@media only screen and (max-width : 768px) {
  header .container .header-components {
    &__description {
      h1 {
        font-size: 26px; } }
    a {
      width: 300px;
      height: 60px;
      font-size: 16px;
      padding: 20px 60px; } } }

@media only screen and (max-width : 420px) {
  header {
    height: 100vh;
    position: relative;
    .container {
      .header-components {
        justify-content: space-between;
        height: calc(100vh - 30vh);
        width: 80%;
        &__description {
          margin-top: 35%;
          h1 {
            font-size: 22px; }
          p {
            width: 300px; } }
        div {
          a {
            width: 350px;
            position: absolute;
            bottom: -60px;
            left: 0;
            margin-top: 30%;
            font-size: 15px; } } } } } }

@media only screen and (max-width : 380px) {
  header .container .header-components div a {
    width: 300px; } }



