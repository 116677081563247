@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

html, body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-text-size-adjust : 100%;
    text-size-adjust: 100%;
    scroll-behavior: smooth;
    overflow-x:hidden;
}
.no-scroll{
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    overflow-y: hidden;
}
* {
    font-family: 'Montserrat', sans-serif;
}
select,
textarea,
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="button"],
input[type="submit"]{
    -webkit-appearance:none;
    -webkit-border-radius:0;
    border-radius:0;
}