.burger-menu-container {
 position: relative;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 margin: 0 auto;
 width: 700px;
 .burger-menu {
   background-color: transparent;
   border: none;
   padding-top: 3%; } }

@media only screen and (max-width : 768px) {
  .burger-menu-container {
    width: 600px; } }

@media only screen and (max-width : 650px) {
  .burger-menu-container {
    width: 500px; } }

@media only screen and (max-width : 550px) {
  .burger-menu-container {
    width: 450px!important; } }

@media only screen and (max-width : 500px) {
  .burger-menu-container {
    width: 400px!important; } }

@media only screen and (max-width : 450px) {
  .burger-menu-container {
    width: 350px!important; } }

@media only screen and (max-width : 380px) {
  .burger-menu-container {
    width: 300px!important; } }
