.collaboration {
  background-color: #3590D6;
  height: 100%;
  padding: 8% 0;
  box-sizing: border-box;
  .container {
    width: 1200px;
    height: auto;
    margin: 0 auto; } }

.accordion {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 30px; }

.accordion__heading {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 18px 8px;
  margin: 0;
  background: transparent;
  color: white;
  border-top: 1px solid #ffffff50;
  border-bottom: 1px solid #ffffff50;
  font-weight: 600;
  font-size: 24px; }

.accordion__heading:before {
  content: url("../../media/img/arrow.svg");
  position: absolute;
  width: 12px;
  height: 3px;
  top: 35%;
  right: 25px;
  transition: all 0.1s; }

.accordion__checkbox:checked~.accordion__heading:before {
  transform: rotate(180deg);
  top: 70%;
  right: 10px; }

.accordion__checkbox {
  display: none;
  position: absolute;
  left: -9999em; }

.accordion__checkbox:checked~.accordion__content {
  max-height: 1000px;
  transition: all 2s;
  padding: 25px; }

.accordion__content {
  max-height: 0;
  overflow: hidden;
  padding: 0 25px;
  transition: all 0.5s; }

@media only screen and (max-width : 1024px) {
  .accordion__heading {
    font-size: 18px; } }

@media only screen and (max-width : 1024px) {
  .collaboration {
    padding: 14% 0; } }
