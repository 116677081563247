#news {
  background-color: #3590D6;
  height: 100%;
  padding: 5% 0 10% 0;
  box-sizing: border-box;
  .container {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    .news__title {
      padding-bottom: 7%; } }
  .news__items {
    padding-left: 8vw;
    .news__items_item {
      box-sizing: content-box;
      display: inline-block;
      width: 335px;
      max-width: 320px;
      height: 350px;
      background: rgba(255, 255, 255, 0.1);
      padding: 36px;
      overflow: hidden;
      h3 {
        padding-bottom: 5%; }
      h4 {
        padding-bottom: 5%; }
      p {
        height: 240px;
        line-height: 28px;
        color: transparent;
        -webkit-background-clip: text;
        background-image: linear-gradient(to bottom, #fff,rgba(255, 255, 255, 0)); } } } }



.slick-slider .slick-track, .slick-slider .slick-list {
  width: 100%;
  overflow: hidden; }



@media only screen and (max-width : 1720px) {
  #news {
    .news__items {
      .news__items_item {
        max-width: 310px;
        p {
          height: 270px; } } } } }




@media only screen and (max-width : 1440px) {
  #news {
    padding-top: 5%;
    .container .news__title {
      padding-bottom: 5%; }
    .news__items {
      .news__items_item {
        max-width: 290px;
        p {
          height: 240px; } } } } }


@media only screen and (max-width : 1350px) {
  #news {
    .news__items {
      padding-left: 3vw; } } }

@media only screen and (max-width : 1250px) {
  #news {
    .news__items {
      padding-left: 8vw;
      .news__items_item {
        max-width: 265px;
        p {
          height: 250px; } } } } }

@media only screen and (max-width: 1120px) {
  #news .news__items {
    padding-left: 3vw;
    .news__items_item {
      max-width: 255px; } } }



@media only screen and (max-width : 1024px) {
  #news {
    padding: 10% 0;
    height: 100%;
    .news__items {
      padding-left: 9%;
      .news__items_item {
        max-width: 270px;
        p {
          height: 295px; } } } } }

@media only screen and (max-width : 950px) {
  #news {
    padding-top: 10%;
    .container {
      margin: 0 auto; }
    .news__items {
      width: 700px!important;
      margin: 0 auto;
      padding-left: 0;
      .news__items_item {
        max-width: 260px;
        p {
          height: 260px; } } } } }

@media only screen and (max-width : 768px) {
  #news {
    padding-top: 14%;
    .container {
      margin: 0 auto; }
    .news__items {
      width: 600px!important;
      margin: 0 auto;
      padding-left: 0;
      .news__items_item {
        max-width: 212px;
        p {
          height: 282px; } } } } }

@media only screen and (max-width : 650px) {
  #news {
    .news__items {
      width: 400px!important;
      margin: 0 auto;
      padding-left: 0;
      .news__items_item {
        max-width: 80%; } } } }

@media only screen and (max-width : 600px) {
  #news {
    padding: 10% 0 14% 0;
    .news__items {
      padding-left: 0;
      width: 340px!important;
      .news__items_item {
        max-width: 260px;
        p {
          height: 240px; } } } } }

@media only screen and (max-width : 450px) {
  #news .container .news__title {
    padding-bottom: 30px; } }

@media only screen and (max-width : 380px) {
  #news {
    .news__items {
      padding-left: 0;
      width: 300px!important;
      .news__items_item {
        max-width: 220px;
        p {
          height: 260px; } } } } }

@media only screen and (max-width : 330px) {
  #news {
    .news__items {
      padding-left: 0;
      width: 285px!important;
      .news__items_item {
        max-width: 225px;
        padding: 20px;
        p {
          height: 260px; } } } } }




